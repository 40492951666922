
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, OAuthProvider, signOut, fetchSignInMethodsForEmail } from "firebase/auth";

import { collection, getFirestore, doc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1_28c9X76_Is6Ehn8f6XqX_PKOZyxAHs",
  authDomain: "chippenhamlife.com",
  projectId: "chippenhamlife",
  storageBucket: "chippenhamlife.appspot.com",
  messagingSenderId: "595888173124",
  appId: "1:595888173124:web:c3fc17e219c55bb04a0c11",
  measurementId: "G-QXX40E2GW5"
};

// Initialize Firebase
initializeApp(firebaseConfig);
//getAnalytics(app);

const auth = getAuth();

function checkEmail(email, callback) {
    fetchSignInMethodsForEmail(auth, email).then((emailResponse) => {
        callback(emailResponse);
    });
}

function register(email, password, displayName, callback, error) {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            callback(user);
            // ...
        })
        .catch((e) => {
            error(e);
            // ..
        });
}

function signIn(email, password, callback, error) {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        callback(user);
        // ...
    })
    .catch((e) => {
        error(e);
    });
}

function logOut(callback, error) {
    signOut(auth).then(() => {
    // Sign-out successful.
        callback()
    }).catch((e) => {
    // An error happened.
        error(e);
    });
    
}

function signInPopup(provider, callback) {
    signInWithPopup(auth, provider)
    .then((result) => {
        const user = result.user;

        callback(user);
    }).catch((error) => {
        
        
        console.log(error);
    });
}

function signInGoogle(callback) {
    const provider = new GoogleAuthProvider();
    signInPopup(provider, callback);
}

function signInFacebook(callback) {
    const provider = new FacebookAuthProvider();
    signInPopup(provider, callback);
}


function signInMicrosoft(callback) {
    
    const provider = new OAuthProvider('microsoft.com');
    signInPopup(provider, callback);

}

function signInTwitter(callback) {
    const provider = new TwitterAuthProvider();
    signInPopup(provider, callback);
}

const db = getFirestore();

function saveData(collectionName, id, data) {
    if (id == null) {
        id = uuidv4();
    }
    setDoc(doc(db, collectionName, id), data);
}

function saveSubData(collectionName, parentId, subcollection, id, data) {
    if (id == null) {
        id = uuidv4();
    }
    setDoc(doc(db, collectionName, parentId, subcollection, id), data);
    
}

function deleteSubData(collectionName, parentId, subcollection, id) {
    deleteDoc(doc(db, collectionName, parentId, subcollection, id));
    
}





// Add a new document in collection "cities"

function getData(collection, id, callback) {
    onSnapshot(doc(db, collection, id), (doc) => {
        callback(doc.data());
        //console.log("Current data: ", doc.data());
    });
}

function getSubData(collectionName, parentId, subcollection, callback) {
    
    const col = collection(db, collectionName, parentId, subcollection);
    onSnapshot(col, (querySnapshot) => {
        const subData = [];
        querySnapshot.forEach((doc) => {
            subData.push(doc.data());
        });
        callback(subData);
    });

}

const storage = getStorage();


function saveFile(path, file, callback) {
    const saveRef = ref(storage, path);
    console.log('Saving file');

    uploadBytes(saveRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file!');
        callback(snapshot);
    });
}

function getFile(path, callback) {
    getDownloadURL(ref(storage, path))
    .then((url) => {
        callback(url);
    })
    .catch(() => {
        // Handle any errors
    });
}



export { register, signIn, signInGoogle, signInFacebook, signInMicrosoft, signInTwitter, logOut, auth, checkEmail, saveData, saveSubData, getData, getSubData, deleteSubData, saveFile, getFile};

<template>
  <div id="ourtown">
    
    <MapView v-bind:setup="setup" v-on:create="createPoint" v-on:place="showPlace" v-on:close="closePanels"/>
    <div class="blackcover" v-if="setup.welcome"></div>
    <div id="logoholder" class="panel">
      <img class="logo" alt="Chippenham Life" src="./assets/logo.svg">
      <div class="icon" v-on:click="setup.menu = !setup.menu; setup.create.active = false; setup.place = null;">
        <font-awesome-icon icon="bars" />
      </div>
    </div>
    <div id="buttonholder" class="buttonholder panel">
      <ButtonView icon="heart" label="Places people love" colour="#CE3131" v-bind:selected="setup.setting == 'love'" v-on:click="setMode('love')"/>
      <ButtonView icon="lightbulb" label="Ideas for a better town" colour="#FFA600" v-bind:selected="setup.setting == 'ideas'" v-on:click="setMode('ideas')"/>
    </div>
    <Transition>
      <CreateView v-if="setup.create.active" v-bind:setup="setup" v-on:close="closePanels" v-on:created="setup.create.active = false; setup.create.location = null;"/>
    </Transition>
    <Transition>
      <MenuView v-if="setup.menu" v-bind:setup="setup" v-on:close="closePanels" v-on:user="userDetails" v-on:auth="showAuth" v-on:terms="showTerms"/>
    </Transition>
    <Transition>
      <PlaceView v-if="setup.place != null && setup.placeDetails[setup.place] != undefined" v-bind:setup="setup" v-on:close="closePanels" v-on:auth="showAuth"/>
    </Transition>
    <WelcomeView v-if="setup.welcome" v-bind:setup="setup" v-on:agree="storeAgreement" v-on:terms="showTerms"/>
    <Transition>
      <AuthView v-if="setup.auth == true" v-on:user="userDetails" v-on:close="setup.auth = false;"/>
    </Transition>
    <Transition>
      <TermsView v-if="setup.terms == true" v-on:close="setup.terms = false;"/>
    </Transition>


    <!--

      TO DO:

      - Delete your account, and all information you have published.
      - See everything you have posted in one screen.
      - Report content - probably need a link on every place page.

      
      - Edit title of place


    -->
    
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import MapView from './components/MapView.vue'
import ButtonView from './components/ButtonView.vue'
import CreateView from './components/CreateView.vue'
import MenuView from './components/MenuView.vue'
import PlaceView from './components/PlaceView.vue'
import WelcomeView from './components/WelcomeView.vue'
import AuthView from './components/AuthView.vue'
import TermsView from './components/TermsView.vue'

import { auth, getData, getSubData, getFile } from './server/firebase.js'
import { onAuthStateChanged } from "firebase/auth";


export default {
  name: 'App',
  components: {
    MapView,
    ButtonView,
    CreateView,
    MenuView,
    PlaceView,
    WelcomeView,
    AuthView,
    TermsView
  },
  data: function() {
    return {
      setup: {
        auth: false,
        welcome: true,
        user: null,
        users: {},
        setting: 'love',
        menu: false,
        places: {},
        ideas: {},
        place: null,
        idea: null,
        placeDetails: {},
        photos: {},
        categories: {
          'fooddrink': {
            label: 'Food and drink',
            colour: '#FF6200',
            icon: 'utensils'
          },
          'shops': {
            label: 'Shops',
            colour: '#00D474',
            icon: 'shopping-bag'
          },
          'outdoors': {
            label: 'Outdoor spaces',
            colour: '#4FCF00',
            icon: 'leaf'
          },
          'education': {
            label: 'Education and childcare',
            colour: '#0800FF',
            icon: 'school'
          },
          'community': {
            label: 'Community facilities',
            colour: '#C5D600',
            icon: 'book-reader'
          },
          'arts': {
            label: 'Arts and entertainment',
            colour: '#FF0000',
            icon: 'guitar'
          },
          'attractions': {
            label: 'Landmarks and attractions',
            colour: '#009EFF',
            icon: 'landmark'
          },
          'transport': {
            label: 'Transport',
            colour: '#EA00FF',
            icon: 'bus'
          }
        },
        create: {
          active: false,
          location: null
        }
      }
    }
  },
  methods: {
    showAuth: function() {
      this.setup.auth = true;
    },
    userDetails: function(userId, userDetail) {
      this.setup.users[userId] = userDetail;
    },
    setCookie: function(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie: function(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    storeAgreement: function() {
      this.setup.welcome = false;
      this.setCookie('consent', 'given', 365);
    },
    showTerms: function() {
      this.setup.terms = true;
    },
    setMode: function(mode) {
      this.closePanels();
      this.setup.setting = mode;
      
    },
    closePanels: function() {
      console.log('Close panels');
      this.setup.menu = false;
      this.setup.create.active = false;
      this.setup.create.location = null;
      this.setup.place = null;
    },
    createPoint: function(location) {
      this.closePanels();
      this.setup.create.active = true;
      this.setup.create.location = location;
      
    },
    showPlace: function(id) {
      var ot = this;
      this.closePanels();

      this.setup.place = id;

      if (this.setup.placeDetails[id] == undefined) {
        ot.setup.placeDetails[id] = {
          details: null,
          comments: [],
          photos: []
        };

        getData('places', id, function(data) {
          if (data != undefined) {
            ot.setup.placeDetails[id].details = data;
          }
        });

        getSubData('places', id, 'comments', function(data) {
          if (data != undefined) {
            ot.setup.placeDetails[id].comments.splice(0);
            data.sort(function(a, b) {
              return a.date - b.date;
            });
            for (var i=0; i<data.length; i++) {
              ot.setup.placeDetails[id].comments.push(data[i]);
            }
          }
        });

        getSubData('places', id, 'photos', function(data) {
          if (data != undefined) {
            ot.setup.placeDetails[id].photos.splice(0);
            for (var i=0; i<data.length; i++) {
              ot.setup.placeDetails[id].photos.push(data[i]);

              ot.getPhoto(data[i]);
            }
          }
        });
      }

      setTimeout(function() {
        ot.setup.place = id;
      }, 100);
    },
    getPhoto(photo) {
      var ot = this;
      getFile(photo.path, function(url) {
        ot.setup.photos[photo.id] = url;
      })
    }
  },
  mounted: function() {
    if (this.getCookie('consent') == 'given') {
      this.setup.welcome = false;
    }

    /*document.addEventListener('click', function (event) {

      console.log(event);

    }, false);*/

    var ot = this;
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        //const uid = user.uid;
        ot.setup.user = user;
        ot.setup.auth = false;
        console.log(user);
        
        getData('users', user.uid, function(data) {
          console.log(data);
          if (data != undefined) {
            ot.setup.users[user.uid] = data;
          } else {
            ot.setup.users[user.uid] = {
              display: ''
            }
          }
        });
        // ...
      } else {
        // User is signed out
        // ...
        ot.setup.user = null;
      }
    });

    getData('places', 'places', function(data) {
      if (data != undefined) {
        ot.setup.places = data;
      }
    });

    getData('places', 'ideas', function(data) {
      if (data != undefined) {
        ot.setup.ideas = data;
      }
    });

    

  }
}
</script>

<style lang="scss">

@import "./styles/_variables.scss";

#ourtown {
  font-family: $font;
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  h1, h2, h3, p {
    font-weight: 700;
    margin: 0;
    margin-bottom: $padding;
  }

  h1 {
    font-size: 20px;
    color: $black;
  }

  h2 {
    font-size: 16px;
    color: $blue;
  }

  h3 {
    font-size: 14px;
    color: $grey;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: $black;
  }

  ul {
    margin: 0;
    margin-bottom: $padding;
    padding-left: $padding*2;
    font-size: 12px;
    font-weight: 400;

    li {
      margin-bottom: $padding/2;
    }
  }

  .panel {
    padding: $padding;
    position: absolute;
    background-color: $white;
    border-radius: $radius;
    left: $padding;
    /*max-width: calc(100% - $padding*4);*/
    width: 300px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    overflow: auto;

    &.full {
      top: $logoheight + $padding*4;
      max-height: calc(100% - #{$padding*10 + $logoheight + $buttonheight});
    }

    
  }

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: $grey;
    padding: $padding;
    transition: 0.25s;

    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }

  #logoholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    top: $padding;
    height: $logoheight;
    overflow: hidden;

    .logo {
      height: $logoheight;
    }

    .icon {
      font-size: 20px;
      padding: $padding/2;
      transition: 0.25s;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }

  #mapholder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #buttonholder {
    bottom: $padding;
    padding-top: 0;
    padding-bottom: 0;
  }

  .buttonholder {
    display: flex;
    justify-content: space-around;
    
  }

  .button-row {
    display: flex;
    gap: $padding;
  }

  button {
    background-color: $blue;
    color: $white;
    padding: $padding - $border;
    border-radius: $innerradius;
    border: $border solid $blue;
    cursor: pointer;
    font-family: $font;
    font-weight: 700;
    margin: 0;
    display: flex;
    gap: $padding/2;
    align-items: center;
    
    &:hover {
      background-color: $white;
      color: $blue;
    }

    &.full {
      width: 100%;
    }

  }

  $colours: ("red": $red, "blue": $blue, "yellow": $yellow);

  @each $name, $colour in $colours {
    .#{$name} {
      color: $colour;
    }
  }

  $icons: ("google": #de5246, "facebook": #4267B2, "microsoft": #737373, "twitter": #1DA1F2);

  @each $name, $colour in $icons {
    button.#{$name} {
      background-color: $colour;
      border-color: $colour;

      &:hover {
        background-color: $white;
        color: $colour;
      }
    }
  }

  .fullpane {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      backdrop-filter: blur(15px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .fullpaneholder {
        position: relative;
        width: calc(100% - #{$padding*2});
        max-width: 400px;
        background-color: $white;
          border-radius: $padding;
          box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
          margin: $padding;
          max-height: calc(100% - #{$padding*2});
      }


      .fullpanecontent {
          position: relative;
          padding: $padding;
          overflow: auto;
          max-height: calc(100% - #{$padding*2});

          .logo {
              height: $logoheight;
              margin-bottom: $padding;
          }

      }
  }
}

input[type=file] {
  margin-bottom: $padding;
}

@media only screen and (max-width: 750px) {
  #ourtown {
    .panel {
      width: calc(100% - $padding*4);
    }
  }
}

.red {
  color: $red;
}

.blue {
  color: $blue;
}

.yellow {
  color: $yellow;
}

.flex {
  display: flex;
  align-items: center;
  gap: $padding;
}

img.profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-bottom: $padding;
}

.hidden {
  display: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.blackcover {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>

import { createApp } from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart, faLightbulb, faUtensils, faShoppingBag, faLeaf, faSchool, faBookReader, faGuitar, faLandmark, faBus, faTag, faUserTag, faEnvelope, faKey, faUserCircle, faPlusCircle, faTimes, faBars, faQuestionCircle, faTrash, faThumbsUp, faRotate, faAnglesRight, faCircleArrowLeft, faCircleArrowRight, faArrowRightFromBracket, faFloppyDisk, faComment, faFileLines} from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faFacebook, faMicrosoft, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faHeart, faLightbulb, faUtensils, faShoppingBag, faLeaf, faSchool, faBookReader, faGuitar, faLandmark, faBus, faTag, faUserTag, faEnvelope, faKey, faUserCircle, faPlusCircle, faTimes, faBars, faQuestionCircle, faTrash, faThumbsUp, faRotate, faAnglesRight, faGoogle, faFacebook, faMicrosoft, faTwitter, faCircleArrowLeft, faCircleArrowRight, faArrowRightFromBracket, faFloppyDisk, faComment, faFileLines)




const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')

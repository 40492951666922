<template>
    <div class="panel full">
        <div class="close" v-on:click="$emit('close')">
            <font-awesome-icon icon="times" />
        </div>

        <div v-if="setup.place != null && setup.placeDetails[setup.place].details != undefined">
            <div class="placeicon" v-bind:style="{'background-color': setup.categories[setup.placeDetails[setup.place].details.category].colour}">
                <font-awesome-icon v-bind:icon="setup.categories[setup.placeDetails[setup.place].details.category].icon"/>
            </div>

            <h1>{{ setup.placeDetails[setup.place].details.name }}</h1>

            <div v-if="setup.placeDetails[setup.place].details.type == 'ideas'" class="love">
                <div v-if="setup.placeDetails[setup.place].details.likes.length > 1"><font-awesome-icon icon="heart" /> {{ setup.placeDetails[setup.place].details.likes.length }} people support this idea</div>
                <div v-if="setup.placeDetails[setup.place].details.likes.length == 1"><font-awesome-icon icon="heart" /> {{ setup.placeDetails[setup.place].details.likes.length }} person supports this idea</div>
            </div>
            <div v-if="setup.placeDetails[setup.place].details.type == 'love'" class="love">
                <div v-if="setup.placeDetails[setup.place].details.likes.length > 1"><font-awesome-icon icon="heart" /> {{ setup.placeDetails[setup.place].details.likes.length }} people love this place</div>
                <div v-if="setup.placeDetails[setup.place].details.likes.length == 1"><font-awesome-icon icon="heart" /> {{ setup.placeDetails[setup.place].details.likes.length }} person loves this place</div>
            </div>
            <div class="lovebutton" v-if="setup.user != null">
                <div v-if="setup.placeDetails[setup.place].details.type == 'ideas'" >
                    <button v-if="setup.placeDetails[setup.place].details.likes.indexOf(setup.user.uid) == -1" v-on:click="lovePlace" ><font-awesome-icon icon="plus-circle" /> Support this idea</button>
                    <p v-else>You have already shown your support for this idea</p>
                </div>
                <div v-if="setup.placeDetails[setup.place].details.type == 'love'">
                    <button v-if="setup.placeDetails[setup.place].details.likes.indexOf(setup.user.uid) == -1" v-on:click="lovePlace" ><font-awesome-icon icon="plus-circle" /> Love this place</button>
                    <p v-else>You have already shown your love for this place</p>
                </div>
            </div>
            

            <h2>Photos</h2>
            <div class="photo" v-for="(photo, id) in getUsers(photos)" v-bind:key="id" v-bind:style="photo.style">
                <div class="user" v-if="setup.users[photo.user] != undefined"><font-awesome-icon icon="user-circle" /> {{ setup.users[photo.user].display }}</div>
                <div class="left arrow" v-on:click="photoId -= 1" v-if="photoId > 0"><font-awesome-icon icon="circle-arrow-left" /></div>
                <div class="right arrow" v-on:click="photoId += 1" v-if="photoId < setup.placeDetails[setup.place].photos.length - 1 && setup.placeDetails[setup.place].photos.length > 1"><font-awesome-icon icon="circle-arrow-right" /></div>
            </div>
            <div v-if="setup.user != null">
                <h3>Add a new photo</h3>
                <FileInput v-if="saving == false" @file="previewFile"/>
                <div class="loading" v-if="saving == true"><font-awesome-icon icon="fa-rotate" spin /></div>
            </div>

            <h2>Comments</h2>
            <div class="comments" v-if="setup.place != null && setup.placeDetails[setup.place].comments != undefined">
                <div class="comment" v-for="(comment, id) in getUsers(setup.placeDetails[setup.place].comments)" v-bind:key="id">
                    <div class="contentholder">
                        <p class="content">{{ comment.comment }}</p>
                        <p class="user" v-if="setup.users[comment.user] != undefined">{{ setup.users[comment.user].display }}</p>
                        <!--<p class="date" v-if="comment.date != undefined">{{ timeSince(new Date(comment.date)) }}</p>-->
                    </div>
                    <div v-if="setup.user != null && setup.user != null && comment.user == setup.user.uid">
                        <div class="delete" v-on:click="deleteComment(comment)"><font-awesome-icon icon="trash" /></div>
                    </div>
                </div>
            </div>
            <div v-if="setup.user != null">
                <h3 v-if="setup.place != null && setup.placeDetails[setup.place].details.type == 'love'">Why do you love this place? Add a comment</h3>

                <h3 v-if="setup.place != null && setup.placeDetails[setup.place].details.type == 'ideas'">Add a comment</h3>
                <div v-if="addingComment == false">

                    <InputView icon="comment" label="Add a comment" type="area" v-model="comment"/>
                    <p class="red" v-if="errorMessage != null">{{ errorMessage }}</p>
                    <button v-on:click="addComment">Add comment<font-awesome-icon icon="comment"/></button>
                </div>
            </div>
            <button v-if="setup.user == null && auth == false" v-on:click="$emit('auth')">Sign in or create an account to contribute</button>
            <!--<AuthView v-if="auth == true && setup.user == null"/>-->
        </div>
        <div v-else>Loading</div>
      
        <!--<div class="buttonholder">
            <ButtonView icon="question-circle" label="About" colour="#009EFF" v-bind:selected="mode == 'about'" v-on:click="mode = 'about'"/>
            <ButtonView icon="user-circle" label="Account" colour="#009EFF" v-bind:selected="mode == 'details'" v-on:click="mode = 'details'"/>
        </div>
    <div v-if="mode == 'about'">
        <h1>About Chippenham Life</h1>
    </div>
    <div v-else>
        <div v-if="setup.user != null">
            <h1>My details</h1>
            <button v-on:click="signOut">Sign out</button>
        </div>
        <div v-else>
            <h1>Create an account to contribute to Chippenham Life</h1>
            <AuthView/>
        </div>
    </div>-->
  </div>
</template>

<script>
import InputView from './InputView.vue'
import FileInput from './FileInput.vue'
import { getData, saveData, saveFile, saveSubData, deleteSubData } from '../server/firebase.js'
import { v4 as uuidv4 } from 'uuid';
import {blockedwords} from '../server/blockedwords.js'

var AES = require("crypto-js/aes");
import Utf8 from 'crypto-js/enc-utf8';

export default {
  name: 'CreateView',
  components: {
      InputView,
      FileInput
  },
  computed: {
      photos: function() {
          var photoList = [];
          if (this.setup.place != null && this.setup.placeDetails[this.setup.place].photos != undefined) {
              for (var i=0; i<this.setup.placeDetails[this.setup.place].photos.length; i++) {
                  if (i == this.photoId) {
                    var thisPhoto = this.setup.placeDetails[this.setup.place].photos[i];
                    if (this.setup.photos[thisPhoto.id] != undefined) {
                        photoList.push({
                            style: {
                                'background-image': 'url('+this.setup.photos[thisPhoto.id]+')'
                            },
                            user: thisPhoto.user
                        })
                    }
                  }
              }
          }
          return photoList;
      }
  },
  props: {
    setup: Object
  },
  data: function() {
      var blockedWords = AES.decrypt(blockedwords, "ChippenhamLife").toString(Utf8);
      
      return {
          saving: false,
          comment: '',
          errorMessage: null,
          addingComment: false,
          photoId: Math.floor(Math.random()*this.setup.placeDetails[this.setup.place].photos.length),
          auth: false,
          blocked: blockedWords.split(',')
      }
  },
  methods: {
      getUsers: function(comments) {
          var users = []
          for (var i=0; i<comments.length; i++) {
              if (users.indexOf(comments[i].user) == -1) {
                  users.push(comments[i].user);
              }
          }
          for (var j=0; j<users.length; j++) {
              this.getUser(users[j]);
          }

          return comments;
      },
      getUser: function(user) {
          var ot = this;
          getData('users', user, function(data) {
          if (data != undefined) {
            ot.setup.users[user] = data;
          } else {
            ot.setup.users[user] = {
              display: ''
            }
          }
        });
      },
      lovePlace: function() {
          var thisPlaceDetails = this.setup.placeDetails[this.setup.place].details;
          thisPlaceDetails.likes.push(this.setup.user.uid);
          saveData('places', thisPlaceDetails.id, thisPlaceDetails);

          if (thisPlaceDetails.type == 'love') {

            var places = this.setup.places;
            places[this.setup.place].likes += 1;

            saveData('places', 'places', places);

          } else {

              var ideas = this.setup.ideas;
                ideas[this.setup.place].likes += 1;

                saveData('places', 'ideas', ideas);

          }
      },
      previewFile: function(file, name) {
          var ot = this;
          console.log(file);
          console.log(name);
          this.saving = true;
          //var file = event.target.files[0];
            if (file != null) {

                var fileId = uuidv4();
                var filePath = "images/places/"+this.setup.place+"/"+fileId+"/"+name;
                saveFile(filePath, file, function() {
                    var fileReference = {
                        id: fileId,
                        date: Date.now(),
                        user: ot.setup.user.uid,
                        path: filePath
                    }

                    ot.photoId = ot.setup.placeDetails[ot.setup.place].photos.length;
                    saveSubData('places', ot.setup.place, 'photos', fileId, fileReference);
                    ot.saving = false;

                });

            }
      },
      checkForBlockedWords: function(txt) {
          var checkText = txt.toLowerCase().replace('.',' ').replace(',',' ').replace('!',' ').replace('?',' ').replace('/',' ').split(' ');
          var blockedCount = 0;
          for (var j=0; j<checkText.length; j++) {
              
            for (var i=0; i<this.blocked.length; i++) {
                if (checkText[j].trim() == this.blocked[i].trim().toLowerCase() && checkText[j].trim() != '') {
                    blockedCount += 1;
                }
                /*if (checkText.includes(this.blocked[i].trim().toLowerCase())) {
                    blockedCount += 1;
                }*/
            }
          }
          return blockedCount;
      },
      addComment: function() {
          var ot = this;
          this.error = null;
          console.log(this.comment);
          var checkComment = this.checkForBlockedWords(this.comment);
          
            console.log(checkComment+' blocked words found');

          if (this.comment != "" && checkComment == 0) {

              this.addingComment = true;

                var subcollection = {
                    id: uuidv4(),
                    comment: this.comment,
                    date: Date.now(),
                    user: this.setup.user.uid
                }
                ot.setup.placeDetails[ot.setup.place].comments.push(subcollection);
                saveSubData('places', this.setup.place, 'comments', subcollection.id, subcollection);

                this.comment = "";

                setTimeout(function() {
                    ot.addingComment = false;
                }, 250);

            } else {
                this.errorMessage = "This comment contains words which are on our list of blocked terms. Chippenham Life is a community portal aimed at audiences of all ages. Please edit your comment and try again.";
            }
      },
      deleteComment: function(comment) {
          deleteSubData('places', this.setup.place, 'comments', comment.id);

      },
      timeSince: function(date) {

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;
            var floor = Math.floor(interval);

            if (interval > 1) {
                
                if (floor > 1) {
                    return floor + " years ago";
                } else {
                    return floor + " year ago";
                }
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                floor = Math.floor(interval);
                if (floor > 1) {
                    return floor + " months ago";
                } else {
                    return floor + " month ago";
                }
            }
            interval = seconds / 86400;
            if (interval > 1) {
                floor = Math.floor(interval);
                if (floor > 1) {
                    return floor + " days ago";
                } else {
                    return floor + " day ago";
                }
            }
            interval = seconds / 3600;
            if (interval > 1) {
                floor = Math.floor(interval);
                if (floor > 1) {
                    return floor + " hours ago";
                } else {
                    return floor + " hour ago";
                }
            }
            interval = seconds / 60;
            if (interval > 1) {
                floor = Math.floor(interval);
                if (floor > 1) {
                    return floor + " minutes ago";
                } else {
                    return floor + " minute ago";
                }
            }
            return "Just now";
        }
  },
  mounted: function() {
    //console.log(this.icon);

    
    

  },
  watch: {
      'setup.place': function() {
          this.auth = false;
          this.addingComment = false;
          this.comment = '';
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

#ourtown {
    .placeicon {
        width: 32px;
        height: 32px;
        font-size: 24px;
        border-radius: 50%;
        display: inline-block;
        margin-bottom: $padding;
        padding: $padding;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .photo {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: $innerradius;
        background-size: cover;
        margin-bottom: $padding;
        position: relative;

        .user {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: $padding/2;
            border-bottom-right-radius: $innerradius;
            border-top-left-radius: $innerradius;
            background-color: rgba(0,0,0,0.50);
            color: $white;
            font-size: 9px;
        }

        $arrowHeight: 24px;

        .arrow {
            position: absolute;
            top: calc(50% - #{$arrowHeight/2 + $padding/2});
            height: $arrowHeight;
            width: $arrowHeight;
            padding: $padding/2;
            background-color: rgba(0,0,0,0.50);
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: $blue;
                cursor: pointer;
            }
            
            &.left {
                left: 0;
                border-bottom-right-radius: $innerradius;
                border-top-right-radius: $innerradius;
            }

            &.right {
                right: 0;
                border-bottom-left-radius: $innerradius;
                border-top-left-radius: $innerradius;
            }
        }
    }

    .comments {
        display: flex;
        flex-direction: column;
        gap: $padding;
        margin-bottom: $padding;
    }

    .comment {
        border-radius: $innerradius;
        background-color: $lightgrey;
        padding: $padding;
        display: flex;
        justify-content: stretch;
        align-items: center;
        position: relative;
        gap: $padding;

        .contentholder {
            
            flex: 1 1 auto;
        }

        p {
            margin: 0;
        }

        .delete {
            cursor: pointer;
            flex: 0 0 auto;

            &:hover {
                color: $red;
            }
            
        }
        
        .content {
            flex: 1 0 100%;
        }

        .user, .date {
            flex: 1 1 auto;
            color: $darkgrey;
        }
    }

    .love {
        color: $red;
        margin-bottom: $padding;
    }

    .lovebutton {
        margin-bottom: $padding;
    }
}
</style>



<template>
  <div>
      <input type="file" @change="fileChange"/>
      <div class="hidden">
          <canvas v-bind:id="canvasId" width="1280" height="1280"/>
      </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'FileInput',
  components: {
      
  },
  computed: {
      
  },
  props: {
    setup: Object
  },
  emit: ['file' ],
  data: function() {
      return {
          mode: 'about',
          canvasId: uuidv4()
      }
  },
  methods: {
      fileChange: function(event) {
          var ot = this;
          var file = event.target.files[0];

          var img = document.createElement("img");
            var reader = new FileReader();
            reader.onload = function(e) {
                img.src = e.target.result;

                var waiting = setInterval(function() {
                    var canvas = document.getElementById(ot.canvasId);
                    if (canvas != null) {
                        var ctx = canvas.getContext("2d");
                        //ctx.drawImage(img, 0, 0);

                        var MAX_WIDTH = 1280;
                        var MAX_HEIGHT = 1280;
                        var width = img.width;
                        var height = img.height;

                        if (width > 0) {
                            clearInterval(waiting);

                            if (width > height) {
                                if (width > MAX_WIDTH) {
                                    height *= MAX_WIDTH / width;
                                    width = MAX_WIDTH;
                                }
                            } else {
                                if (height > MAX_HEIGHT) {
                                    width *= MAX_HEIGHT / height;
                                    height = MAX_HEIGHT;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;

                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.toBlob(function(blob){
                                //var image = new Image();
                                //image.src = blob;
                                ot.$emit('file', blob, file.name);
                                //var uploadTask = storageRef.child('images/' + "apple").put(blob);
                            }, 'image/jpeg');
                        }
                    }
                }, 50);

                
            }
            reader.readAsDataURL(file);

            

            
      }
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

</style>

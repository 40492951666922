<template>
  <div class="panel full">
      <div class="close" v-on:click="$emit('close')">
          <font-awesome-icon icon="times" />
      </div>
      
        <div class="buttonholder">
            <ButtonView icon="question-circle" label="About" colour="#009EFF" v-bind:selected="mode == 'about'" v-on:click="mode = 'about'"/>
            <!--<ButtonView icon="landmark" label="Our town" colour="#009EFF" v-bind:selected="mode == 'chippenham'" v-on:click="mode = 'chippenham'"/>-->
            <ButtonView icon="user-circle" label="Account" colour="#009EFF" v-bind:selected="mode == 'details'" v-on:click="mode = 'details'"/>
        </div>
    <div v-if="mode == 'about'">
        <!--<h1>About Chippenham Life</h1>-->
        <WelcomeText/>
        <button v-on:click="$emit('terms')">Terms of service<font-awesome-icon icon="file-lines"/></button>
        <!--<h1>My details</h1>
        <h2>Give it a name</h2>
        <InputView icon="tag" label="Give it a name" type="text"/>
        <h2>Why do you love it?</h2>
        <InputView icon="heart" label="Why do you love it?" type="area"/>
        <h2>What type of place is it?</h2>
        <div class="buttons">
            <ButtonView v-for="(category, key) in setup.categories" v-bind:key="key" v-bind:icon="category.icon" v-bind:label="key" v-bind:colour="category.colour" v-bind:selected="selectedCategory == key" v-on:click="setCategory(key)"/>
        </div>
        <button>Submit your place</button>-->
    </div>
    <!--<div v-else-if="mode == 'chippenham'">
        <h1>About our town</h1>
        <p>Chippenham is a market town in Wiltshire, </p>
    </div>-->
    <div v-else>
        <div v-if="setup.user != null">
            <h1>My details</h1>
            <InputView icon="user-tag" label="Display name" type="text" v-model="displayName"/>
            <div class="button-row">
            <button v-on:click="saveChanges">Save changes<font-awesome-icon icon="floppy-disk"/></button>

            <button v-on:click="signOut">Sign out<font-awesome-icon icon="arrow-right-from-bracket"/></button>
            </div>
        </div>
        <div v-else>
            <h1>Create an account to contribute to Chippenham Life</h1>
            <button v-on:click="$emit('auth')">Sign in or create an account<font-awesome-icon icon="user-circle"/></button>
            <!--<AuthView v-on:user="userDetails"/>-->
        </div>
    </div>
  </div>
</template>

<script>
import ButtonView from './ButtonView.vue'
import InputView from './InputView.vue'

import WelcomeText from './WelcomeText.vue'
import { logOut, saveData } from '../server/firebase.js'


export default {
  name: 'CreateView',
  components: {
      ButtonView,
      InputView,
      WelcomeText
  },
  computed: {
      iconName: function() {
          return "fa-"+this.icon;
      }
  },
  props: {
    setup: Object
  },
  data: function() {
      var displayName = "";
      if (this.setup.users != null && this.setup.user != null) {
          displayName = this.setup.users[this.setup.user.uid].display;
      }
      return {
          mode: 'about',
          displayName: displayName
      }
  },
  methods: {
      saveChanges: function() {
          var myUser = this.setup.users[this.setup.user.uid];
          myUser.display = this.displayName;
          saveData('users', this.setup.user.uid, myUser);
      },
      signOut: function() {
          logOut(function() {
              
          }, function() {

          })
      },
      userDetails: function(userId, userDetail) {
          this.$emit('user', userId, userDetail);
      }
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

</style>

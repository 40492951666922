<template>
  <div class="panel full">
      <WelcomeText></WelcomeText>
      <p></p>
      <h2>Cookies</h2>
      <p>The website uses cookies and local browser storage to keep you signed in, to keep information about places synchronised and to support the background map view. This includes third party services: Google Firebase and Mapbox.</p>
      <p>If you create an account, your display name and email address will be stored, along with any places, ideas, comments and photos you upload.</p>
      <p>By continuing you are agreeing to the <span class="blue" v-on:click="$emit('terms')">terms of service <font-awesome-icon icon="file-lines"/></span>.</p>
      <button v-on:click="$emit('agree')">Agree and get started</button>
      <!--<p></p>
      <p>If you choose, you can also opt in to allow the site to collect information using Google Firebase Analytics.</p>
      <button class="secondary">Agree and get started with analytics</button>-->
  </div>
</template>

<script>
import WelcomeText from './WelcomeText.vue'

export default {
  name: 'WelcomeView',
  components: {
      WelcomeText
  },
  computed: {
      
  },
  props: {
    setup: Object
  },
  data: function() {
      return {
          mode: 'about'
      }
  },
  methods: {
      
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

</style>

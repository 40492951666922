<template>
  <div>
      <h1>Welcome!</h1>
      <p>I created Chippenham Life to be a place for people to show and share their love for Chippenham, with the ambition of changing the way we engage with our town.</p>
      <p>Everybody can see the places and ideas others have added, and you can create an account to:</p>
      <p><span class="red"><font-awesome-icon icon="heart" /></span> Tell us about the places you love, large or small, from shops and pubs to parks and woodland.</p>
      <p><span class="yellow"><font-awesome-icon icon="lightbulb" /></span> Share your ideas for making our town a better place.</p>
      <p><span class="blue"><font-awesome-icon icon="thumbs-up" /></span> Show your support for other ideas, add photos and share your thoughts.</p>
      <p>I am standing in the upcoming by-election in the <b>Cepen Park and Hunters Moon</b> ward on <b>7th April</b>. Whether or not I'm elected, I'll work with the Chippenham's excellent independent and Green <a href="https://www.chippenham.gov.uk/chippenham-town-councillors-2" target="_BLANK">Town Councillors</a> to make sure your voices are heard by the council, and share their responses on this site.</p>
      <p>If you find specific problems that need addressing by the Council, you can use their <a href="https://www.chippenham.gov.uk/report-it" target="_BLANK">'Report-it' service</a>.</p>
      <p>This website is a work in progress, and a personal project - not supported or endorsed by Chippenham Town Council. It's not perfect - but I'm continuing to develop it and hope to iron out any remaining bugs.</p>
      <div class="flex">
        <img class="profile" src="../assets/joe.jpg"/>
        <p>All the best,<br>Joseph Clarke</p>
      </div>
      <!--<p></p>
      <p>If you choose, you can also opt in to allow the site to collect information using Google Firebase Analytics.</p>
      <button class="secondary">Agree and get started with analytics</button>-->
  </div>
</template>

<script>

export default {
  name: 'WelcomeText',
  components: {
      
  },
  computed: {
      
  },
  props: {
    setup: Object
  },
  data: function() {
      return {
          mode: 'about'
      }
  },
  methods: {
      
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

</style>

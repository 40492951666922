<template>
    <div class="fullpane">
        <div class="fullpaneholder">
            <div class="fullpanecontent">
                
                <img class="logo" alt="Chippenham Life" src="../assets/logo.svg">
                <div v-if="mode == null || mode == 'register' || mode == 'signin'">
                    <h1 v-if="mode == null">Sign in or create an account</h1>
                    <h2 v-if="mode == null">Use your email address to register or sign in:</h2>

                    <h1 v-if="mode == 'register'">Create an account</h1>
                    <h2 v-if="mode == 'register'">Use your email address to register:</h2>

                    <h1 v-if="mode == 'signin'">Sign in</h1>
                    <h2 v-if="mode == 'signin'">Use your email address and password to sign in:</h2>
                    <!--<div class="buttonholder">
                        <ButtonView icon="plus-circle" label="Register" colour="#009EFF" v-bind:selected="mode == 'register'" v-on:click="mode = 'register'"/>
                        <ButtonView icon="user-circle" label="Sign in" colour="#009EFF" v-bind:selected="mode == 'signin'" v-on:click="mode = 'signin'"/>
                    </div>-->
                    <!--<h2>Already have an account?</h2>
                    <button>Sign in</button>-->
                    <h2>Email address</h2>

                    <InputView v-if="mode == null" icon="envelope" label="Email address" type="text" v-model="email" v-on:enter="continueSignIn"/>
                    <h3 v-if="mode != null" v-on:click="email = ''; mode = null; message = ''">{{ email }}</h3>
                    <p v-if="message != ''">{{ message }}</p>
                    <div class="button-block">
                        <button v-if="mode == null" v-on:click="continueSignIn">Continue<font-awesome-icon icon="angles-right"/></button>
                    </div>

                    <div class="button-block" v-if="mode == 'register'">
                        <h2>Display name</h2>
                        <h3>This will be seen by other users</h3>
                        <InputView icon="user-tag" label="Display name" type="text" v-model="name"/>
                        <h2>Password</h2>
                        <InputView icon="key" label="Password" type="password" v-model="password" v-on:enter="createAccount"/>
                        <h2 class="error" v-if="registerError != ''">{{ registerError }}</h2>
                        <button v-on:click="createAccount">Create an account</button>
                    </div>
                    <div class="button-block" v-if="mode == 'signin'">
                        <h2>Password</h2>
                        <InputView icon="key" label="Password" type="password" v-model="password" v-on:enter="signIn"/>
                        <h2 class="error" v-if="signInError != ''">{{ signInError }}</h2>
                        <button v-on:click="signIn">Sign in</button>
                    </div>
                    <h2>Or sign in using:</h2>
                    <div class="thirdparty">
                        <button class="full google" v-on:click="signInWithGoogle"><font-awesome-icon :icon="['fab', 'google']"></font-awesome-icon>Sign in with Google</button>
                        <button class="full facebook" v-on:click="signInWithFacebook"><font-awesome-icon :icon="['fab', 'facebook']"></font-awesome-icon>Sign in with Facebook</button>
                        <button class="full microsoft" v-on:click="signInWithMicrosoft"><font-awesome-icon :icon="['fab', 'microsoft']"></font-awesome-icon>Sign in with Microsoft</button>
                    </div>
                    <!--<div class="thirdparty">
                        <button class="full twitter" v-on:click="signInWithTwitter"><font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>Sign in with Twitter</button>
                    </div>-->
                </div>
            </div>
            <div class="close" v-on:click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>

        </div>
    </div>
</template>

<script>
//var firebase = require('firebase');
//var firebaseui = require('firebaseui');
//var ui = new firebaseui.auth.AuthUI(firebase.auth());
import InputView from './InputView.vue'
//import FacebookAuth from './FacebookAuth.vue'
//import ButtonView from './ButtonView.vue'

import { register, signIn, saveData, signInGoogle, signInFacebook, signInMicrosoft, checkEmail } from '../server/firebase.js'


export default {
  name: 'AuthView',
  components: {
      InputView,
      //FacebookAuth
      //ButtonView
  },
  computed: {
      
  },
  props: {
    
  },
  data: function() {
      return {
          message: '',
          mode: null,
          email: '',
          password: '',
          name: '',
          registerError: '',
          signInError: ''

      }
  },
  methods: {
      continueSignIn: function() {
          var ot = this;
          if (ot.email != '' && ot.email.replace('@', '') != ot.email && ot.email.replace('@','').split('.').length > 1) {
            checkEmail(this.email, function(emailResponse) {
                console.log(emailResponse);
                    if (emailResponse.indexOf('password') != -1) {
                        ot.mode = 'signin';//'register'
                    } else if (emailResponse.indexOf('google.com') != -1) {
                        ot.message = "Use the 'Sign in with Google' button to sign in";
                    } else {
                        console.log(emailResponse);
                    }
                    if (emailResponse.length == 0) {
                        ot.mode = 'register';
                    }
            });
          }
      },
      createAccount: function() {
          var ot = this;
          
        register(this.email, this.password, this.displayName, function(user) {//user) {
            //ot.$emit('user', user);
            var myUser = {
                display: ot.name
            }
            ot.$emit('user', user.uid, myUser);
            saveData('users', user.uid, myUser);

            
        }, function() {

        })/*;error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            ot.registerError = errorMessage;
        });*/

      },
      signIn: function() {
          
          signIn(this.email, this.password, function() {
              
            }, function() {

            });

          /*signIn(this.email, this.password, function(user) {
              console.log(user);
                //ot.$emit('user', user);
            }, function(error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
                ot.signInError = errorMessage;
            });*/
      },
      signInWithGoogle: function() {
          signInGoogle(function() {
              
          })
      },
      signInWithFacebook: function() {
          signInFacebook(function() {
              
          })
      },
      signInWithMicrosoft: function() {
        signInMicrosoft(function() {
              
          })
      },
      /*signInWithTwitter: function() {
        signInTwitter(function() {
              
          })
      }*/
  },
  mounted: function() {
    /*ui.start('#authholder', {
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false
            },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.MicrosoftAuthProvider.PROVIDER_ID,
        ],
        // Other config options...
    });*/
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    @import "../styles/_variables.scss";

    
    

    .button {
        text-align: center;
        padding: $padding;
        border-radius: $radius;
        cursor: pointer;
        color: $grey;


        .icon {
            font-size: 32px;
            opacity: 0.3;
            transition: 0.25s;
        }
        .label {
            font-size: 12px;
            transition: 0.25s;
        }

        &.active {
            color: $blue;

            .icon {
                opacity: 1;
            }
            /*background-color: $blue;
            color: $white;*/
        }

        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }

    .button-block {
        margin-bottom: $padding;
    }

    .thirdparty {
        display: flex;
        flex-direction: column;
        gap: $padding;
    }

    img.clickable {
        cursor: pointer;
    }
</style>

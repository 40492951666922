<template>
  <div class="panel full" id="createpanel">
      <div class="close" v-on:click="$emit('close')">
          <font-awesome-icon icon="times" />
      </div>
      <div v-if="setup.user != null">
          <div v-if="setup.setting == 'love'">
            <h1>Add a place</h1>
            <h3>What makes you proud of Chippenham?</h3>
            <h2>What is the name of the place?</h2>
            <InputView icon="tag" label="What is the name of the place?" type="text" v-model="name"/>
            <p class="red" v-if="nameError != null">{{ nameError }}</p>
                    
            <h2>Why do you love it?</h2>
            <h3>This will be added to the place as a comment</h3>
            <InputView icon="heart" label="Why do you love it?" type="area" v-model="description"/>
            <p class="red" v-if="descriptionError != null">{{ descriptionError }}</p>
            <h2>What type of place is it?</h2>
            <div class="buttons">
                <ButtonView v-for="(category, key) in setup.categories" v-bind:key="key" v-bind:icon="category.icon" v-bind:label="category.label" v-bind:colour="category.colour" v-bind:selected="selectedCategory == key" v-on:click="setCategory(key)"/>
            </div>

            <!--<h2>Do you have a photo?</h2>
            <FileInput @change="previewFile"/>-->
        </div>
        <div v-if="setup.setting == 'ideas'">
            <h1>Suggest an idea</h1>
            <h3>How could we make Chippenham a better place?</h3>
            <h2>What is your idea?</h2>
            <InputView icon="lightbulb" label="What is your idea?" type="area" v-model="name"/>
            <p class="red" v-if="nameError != null">{{ nameError }}</p>
            <h2>What type of idea is it?</h2>
            <div class="buttons">
                <ButtonView v-for="(category, key) in setup.categories" v-bind:key="key" v-bind:icon="category.icon" v-bind:label="category.label" v-bind:colour="category.colour" v-bind:selected="selectedCategory == key" v-on:click="setCategory(key)"/>
            </div>
            <!--<h2>Do you have a photo?</h2>
            <FileInput @change="previewFile"/>-->
        </div>

    <button v-on:click="savePlace">Submit your place</button>
    </div>
    <div v-if="setup.user == null">
        <h1>Create an account or sign in to add places</h1>

        <AuthView/>
    </div>
  </div>
</template>

<script>
import ButtonView from './ButtonView.vue'
import InputView from './InputView.vue'
import AuthView from './AuthView.vue'
//import FileInput from './FileInput.vue'
import { saveData, saveSubData, saveFile } from '../server/firebase.js'
import { v4 as uuidv4 } from 'uuid';
import {blockedwords} from '../server/blockedwords.js'
var AES = require("crypto-js/aes");
import Utf8 from 'crypto-js/enc-utf8';

export default {
  name: 'CreateView',
  components: {
      ButtonView,
      InputView,
      AuthView,
      //FileInput
  },
  computed: {
      iconName: function() {
          return "fa-"+this.icon;
      }
  },
  props: {
    setup: Object
  },
  data: function() {
      var blockedWords = AES.decrypt(blockedwords, "ChippenhamLife").toString(Utf8);

      return {
          selectedCategory: null,
          name: '',
          description: '',
          nameError: null,
          descriptionError: null,
          file: null,
          fileName: null,
          blocked: blockedWords.split(',')
      }
  },
  methods: {
      setCategory: function(key) {
          this.selectedCategory = key;
      },
      onSignIn: function(user) {
          this.$emit('user', user);
      },
      previewFile: function(file, fileName) {
            this.file = file;
            this.fileName = fileName;
      },
      checkForBlockedWords: function(txt) {
          var checkText = txt.toLowerCase().replace('.',' ').replace(',',' ').replace('!',' ').replace('?',' ').replace('/',' ').split(' ');
          var blockedCount = 0;
          for (var j=0; j<checkText.length; j++) {
              
            for (var i=0; i<this.blocked.length; i++) {
                if (checkText[j].trim() == this.blocked[i].trim().toLowerCase() && checkText[j].trim() != '') {
                    blockedCount += 1;
                }
                /*if (checkText.includes(this.blocked[i].trim().toLowerCase())) {
                    blockedCount += 1;
                }*/
            }
          }
          return blockedCount;
      },
      savePlace: function() {
          var ot = this;
          //console.log(this.setup.create.location);
          //console.log(this.setup.user);
          var placeId = uuidv4();


            this.nameError = null;
            this.descriptionError = null;
          var nameCheck = this.checkForBlockedWords(this.name);
          var descriptionCheck = this.checkForBlockedWords(this.description);

          if (nameCheck > 0) {
              this.nameError = "This text contains words which are on our list of blocked terms. Chippenham Life is a community portal aimed at audiences of all ages. Please edit your comment and try again."
          } else if (descriptionCheck > 0) {
              this.descriptionError = "This comment contains words which are on our list of blocked terms. Chippenham Life is a community portal aimed at audiences of all ages. Please edit your comment and try again."
          } else {



            var placeSummary = {
                name: this.name,
                category: this.selectedCategory,
                id: placeId,
                lat: this.setup.create.location.lat,
                lng: this.setup.create.location.lng,
                likeCount: 1
            }

            if (this.setup.setting == 'love') {

                var places = this.setup.places;
                places[placeId] = placeSummary;

                saveData('places', 'places', places);

            } else {

                var ideas = this.setup.ideas;
                    ideas[placeId] = placeSummary;

                    saveData('places', 'ideas', ideas);

            }

            var placeDetails = {
                name: this.name,
                category: this.selectedCategory,
                id: placeId,
                lat: this.setup.create.location.lat,
                lng: this.setup.create.location.lng,
                likes: [this.setup.user.uid],
                created: Date.now(),
                type: this.setup.setting,
                owners: [this.setup.user.uid]
            }

            saveData('places', placeId, placeDetails);

                if (this.setup.setting.love && this.description != "") {

                    var subcollection = {
                        id: uuidv4(),
                        comment: this.description,
                        date: Date.now(),
                        user: this.setup.user.uid
                    }
                    saveSubData('places', placeId, 'comments', subcollection.id, subcollection);

                }

                if (this.file != null) {

                    var fileId = uuidv4();
                    var filePath = "images/places/"+placeId+"/"+fileId+"/"+this.fileName;
                    saveFile(filePath, this.file, function() {
                        var fileReference = {
                            id: fileId,
                            date: Date.now(),
                            user: ot.setup.user.uid,
                            path: filePath
                        }
                        saveSubData('places', placeId, 'photos', fileId, fileReference);
                    });

                }

                this.$emit('created');

            }

          
      }
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

    #createpanel {
        

        button {
            width: 100%;
        }
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .button {
            width: 80px;
        }
    }
</style>

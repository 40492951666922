<template>
  <div class="fullpane">
    <div class="fullpaneholder">
      
      <div class="fullpanecontent">
          <img class="logo" alt="Chippenham Life" src="../assets/logo.svg">
          <div>
              <h1>Terms of service</h1>
              <h2>Using the site</h2>
              <p>When using the site, the following terms apply:</p>
              <ul>
                  <li>Chippenham Life is a community portal, and does not directly publish the material you see as a user. Chippenham Life is not responsible for the material which has been published. However, we take pride in providing a safe environment for people to share positive stories about the places they love, and (as described below) will remove any content which is deemed harmful or adult-only.</li>
                  <li>All material published on Chippenham Life is published under a <a href="https://creativecommons.org/licenses/by/4.0/" target="_BLANK">Creative Commons Attribution (CC BY 4.0) licence</a>. This means it can be freely re-used for any purpose, but must be attributed correctly to its originator.</li>
              </ul>
              <h2>Creating an account</h2>
              <p>When creating an account on Chippenham Life, the following terms apply:</p>
              <ul>
                  <li>Accounts can only be created by adults. Use by children should be under adult supervision.</li>
                  <li>To manage your account, we store an email address, display name and password. We use Google Firebase to manage data, including user accounts. If you register using a third party account, Chippenham Life requests access to your basic profile information to enable you to sign in with Chippenham Life, including the email address associated with your third party account. We do not request or store other profile information or post on your behalf.</li>
                  <li>All content you publish (see the following section) is associated with your user account. At any point, you can delete your account. You will have the option to delete all content you have published.</li>
              </ul>
              <h2>Publishing content</h2>
              <p>By publishing content on Chippenham Life, which can include identification of locations, ideas, comments and media (such as photographs), you must adhere to the following terms:</p>
              <ul>
                  
                  <li>Chippenham Life is aimed at audiences of all ages. You must not post any comments which include harmful or adult content. This includes the use of swear words or sexual, graphic or hateful terms, in addition to any illegal content. We operate a zero tolerance policy. Any comments or media found to be breaking this term will be removed, and the responsible account will be blocked.</li>
                  <li>By uploading any material, including media, you agree for it to be made publicly available under a <a href="https://creativecommons.org/licenses/by/4.0/" target="_BLANK">Creative Commons Attribution (CC BY 4.0) licence</a>. Photographs and comments are attributed using a user's display name.</li>
                  <li>Media and other content must not infringe on intellectual property rights. Photographs should only be uploaded if you are the copyright holder, or have permission from the copyright holder, to upload the material.</li>
                  <li>Media should only include people if necessary. You should obtain permission to include any identifiable individuals in media.</li>
              </ul>
              <h2>Requests for removing content</h2>
              <h3>Users' own content</h3>
              <p>Users can remove any content they have published.</p>
              <h3>Harmful content</h3>
              <p>Any harmful content should be reported immediately. It will be removed as soon as reasonably possible, and the responsible account blocked.</p>
              <h3>Intellectual property rights</h3>
              <p>If you are the copyright holder for any user material published on Chippenham Life, you can request its removal.</p>
              <h3>Other reasonable requests</h3>
              <p>We will happily consider any other requests to remove content.</p>
              <button v-on:click="$emit('close')">Close<font-awesome-icon icon="times" /></button>
          </div>
      </div>
      <div class="close" v-on:click="$emit('close')">
          <font-awesome-icon icon="times" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WelcomeText',
  components: {
      
  },
  computed: {
      
  },
  props: {
    setup: Object
  },
  data: function() {
      return {
          mode: 'about'
      }
  },
  methods: {
      
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

</style>

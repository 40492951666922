<template>
  <div>
    <div id="mapholder"></div>
    <div class="hidden" id="mapicons">
      <div v-for="(category, key) in setup.categories" v-bind:key="key">
        <div v-bind:id="'icon-'+key">
          <font-awesome-icon v-bind:icon="category.icon" />
        </div>
      </div>
    </div>
  </div>
  <!--<div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div>-->
</template>

<script>
var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
import { v4 as uuidv4 } from 'uuid';

var map;
//var placeMarkers = [];
var newMarkers = [];
var placeMarkers = [];

export default {
  name: 'HelloWorld',
  props: {
    setup: Object
  },
  data: function() {
    return {
      loaded: false
    }
  },
  mounted: function() {
    var ot = this;



    mapboxgl.accessToken = 'pk.eyJ1Ijoiam9lY2xhcmtlIiwiYSI6ImNpbHFmNm4xYTAwNWN5aW0wdGc2eWgxdXEifQ.V8KWNkNZiUvqnyimttv5cA';

    const bounds = [
      [-2.198625, 51.404161], // Southwest coordinates
      [-2.044129, 51.519454] // Northeast coordinates
    ];
    //var serviceUrl = "https://api.os.uk/maps/vector/v1/vts";
    //var key = 'eF5AnLFYPFWmR71IJ4mLCi4Lg9kSybcY';
    
    map = new mapboxgl.Map({
      container: 'mapholder',
      //style: serviceUrl + '/resources/styles?key=' + key,
      style: 'mapbox://styles/joeclarke/ckzvusi1y001715mfgodwwkus',
      center: [-2.11699, 51.45819],
      zoom: 12,
      logoPosition: 'top-right',
      maxZoom: 15.99,
      maxBounds: bounds,
        /*transformRequest: url => {
            url += '&srs=3857';
            return {
                url: url
            }
        }*/
    });

    /*map.addControl(new mapboxgl.AttributionControl({
        customAttribution: '&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>'
    }));*/
    
    map.on('click', (e) => {
      
      ot.$emit('close');
      
      // Copy coordinates array.
      var nodeNames = ['svg', 'path'];
      if (e.originalEvent.target.classList.contains("new-marker") == false && nodeNames.indexOf(e.originalEvent.target.nodeName) == -1) {

        for (var i=0; i<newMarkers.length; i++) {
          newMarkers[i].remove();
        }
        newMarkers = [];
        
        const coordinates = e.lngLat;
        

        var buttonId = uuidv4();

        var addLabel = 'Add a place here';
        if (ot.setup.setting == 'ideas') {
          addLabel = 'Suggest an idea here';
        }
        const popup = new mapboxgl.Popup({ offset: 12 }).setHTML(
          '<button id="'+buttonId+'" class="popup-button">'+addLabel+'</button>'
        );
        
        
        // create DOM element for the marker
        const el = document.createElement('div');
        el.classList.add('new-marker');

        // create the marker
        var marker = new mapboxgl.Marker(el)
          .setLngLat(coordinates)
          .setPopup(popup) // sets a popup on this marker
          .addTo(map);

        marker.togglePopup();

        const buttonElement = document.getElementById(buttonId);
        buttonElement.addEventListener("click", function() {
          ot.$emit('create', e.lngLat);
        });
        /*const popupElem = popup.getElement();
        const popupContent = popupElem.querySelector('.mapboxgl-popup-content');
        popupContent.style.padding = "0px";
        const popupTip = popupElem.querySelector('.mapboxgl-popup-tip');
        popupTip.style.borderTopColor = "#009EFF";*/
        
        newMarkers.push(marker);

        /*el.addEventListener('click', (e2) => {
          
          marker.togglePopup();
          e2.stopPropagation();
        }, false);
        markers.append(marker);*/
      }
      
    });


    map.on('movestart', () => {
      //ot.$emit('close');

      ot.closePopups();

      
    });

    map.on('load', () => {
      ot.loaded = true;
      
      ot.loadMarkers();
      
    });
  },
  methods: {
    closePopups: function() {
      for (var i=0; i<newMarkers.length; i++) {
        var thisPopup = newMarkers[i].getPopup();
        if (thisPopup.isOpen()) {
          newMarkers[i].togglePopup();
        }
      }
    },
    loadMarkers: function() {


      for (var j=0; j<newMarkers.length; j++) {
        newMarkers[j].remove();
      }
      newMarkers = [];

      
      for (var i=0; i<placeMarkers.length; i++) {
        placeMarkers[i].remove();
      }
      placeMarkers = [];

      var myObject = this.setup.places;
      if (this.setup.setting == 'ideas') {
        myObject = this.setup.ideas;
      }

      for (var key in myObject) {
        this.addMarker(myObject[key]);
      }

      
    },
    addMarker: function(place) {
      var ot = this;
      
        const el = document.createElement('div');

        el.classList.add('new-marker');
        el.classList.add('place-marker');

        el.style = "background-color: "+this.setup.categories[place.category].colour+";";
        this.$nextTick(function() {
          var iconElementHolder = document.getElementById('icon-'+place.category);
          var iconElement = iconElementHolder.firstElementChild;
          var myIconElement = iconElement.cloneNode(true);
          el.appendChild(myIconElement);
        });
        

        var marker = new mapboxgl.Marker(el)
          .setLngLat([place.lng, place.lat])
          .addTo(map);
          placeMarkers.push(marker);

        el.addEventListener("click", function() {
          ot.$emit('place', place.id);
        });
    }
  },
  watch: {
    'setup.places': function() {
          this.loadMarkers();
    },
    'setup.ideas': function() {
        this.loadMarkers();
    },
    'setup.setting': function() {
      this.loadMarkers();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "../styles/_variables.scss";

#ourtown {
  .new-marker {
    width: 16px;
    height: 16px;
    background-color: #FFF;
    border: $border solid $blue;
    border-radius: 50%;

    &.place-marker {
      border: 1px solid $white;
      width: 24px + $border;
      height: 24px + $border;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mapboxgl-popup-content {
    border-radius: $radius;
    font-family: $font;
    padding: 0px;
  }

  .mapboxgl-popup-tip {
    border-top-color: $blue;
  }

  button.mapboxgl-popup-close-button {
    width: 27px;
  }

  button.popup-button {
    padding-right: 27px;
  }


}


</style>

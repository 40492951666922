<template>
  <div class="inputholder">
      <div class="iconholder">
        <div class="icon">
            <font-awesome-icon v-bind:icon="icon" />
        </div>
      </div>
      <!--<div class="label">{{ label }}</div>-->
      <input type="text" v-if="type == 'text'" v-bind:placeholder="label" v-model="content" @input="handleInput" @keyup="keyUp"/>
      <input type="password" v-if="type == 'password'" v-bind:placeholder="label" v-model="content" @input="handleInput"/>
      <textarea rows="4" v-if="type == 'area'" v-model="content" @input="handleInput"/>
  </div>
</template>

<script>

export default {
  name: 'InputView',
  props: {
    icon: String,
    label: String,
    type: String,
    value: String,
    modelValue: String
  },
  emits: ['update:modelValue'],
  data () {
    return {
      content: this.modelValue
    }
  },
  methods: {
    handleInput: function() {
        
        this.$emit('update:modelValue', this.content)
    },
    keyUp: function(event) {
      if (event.keyCode === 13) {
        this.$emit('enter');
      }
    }
  },
  mounted: function() {
    //console.log(this.icon);
  },
  watch: {
    modelValue: function() {
      this.content = this.modelValue;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

    .inputholder {
        border-radius: $innerradius;
        border: $border solid $blue;
        position: relative;
        margin-bottom: $padding;

        .iconholder {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon {
                padding: $padding;
                width: 16px;
            }
        }

        input, textarea {
            font-family: $font;
            font-weight: 400;
            box-sizing: border-box;
            border: 0px;
            width: 100%;
            padding: $padding $padding $padding #{$padding*2 + 16px};
            border-radius: $radius - $border;
            margin: 0;
        }
        
    }

</style>

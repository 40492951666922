<template>
  <div class="button" v-bind:class="{active: selected}" v-on:click="$emit('click')">
      <div class="icon" v-bind:style="{color: colour}">
        <font-awesome-icon v-bind:icon="icon" />
      </div>
      <div class="label">{{ label }}</div>
  </div>
</template>

<script>

export default {
  name: 'ButtonView',
  computed: {
      iconName: function() {
          return "fa-"+this.icon;
      }
  },
  props: {
    icon: String,
    label: String,
    selected: Boolean,
    colour: String
  },
  mounted: function() {
    //console.log(this.icon);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    @import "../styles/_variables.scss";

    .button {
        text-align: center;
        padding: $padding;
        border-radius: $radius;
        cursor: pointer;
        color: $grey;


        .icon {
            font-size: 32px;
            opacity: 0.3;
            transition: 0.25s;
        }
        .label {
            font-size: 12px;
            transition: 0.25s;
        }

        &.active {
            color: $blue;

            .icon {
                opacity: 1;
            }
            /*background-color: $blue;
            color: $white;*/
        }

        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
</style>
